import { MDBSpinner } from 'mdb-react-ui-kit'
import React from 'react'
import styled from 'styled-components'

const Div = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1c1c1c;
`
const PageLoader = () => {
    return (
        <Div className='wow fadeIn'>
            <MDBSpinner className='mx-3' style={{ width: '4rem', height: '4rem' }} color='warning'>
                <span className='visually-hidden'>Loading...</span>
            </MDBSpinner>
        </Div>
    )
}

export default PageLoader