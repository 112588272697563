import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthContextProvider } from './App/context/AuthContext';
import { AuthContextProviderD } from './AdminApp/context/AuthContext';
import Root from './Root';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthContextProvider>
    <AuthContextProviderD>
          <React.StrictMode>
            <Root />
          </React.StrictMode>
    </AuthContextProviderD>
  </AuthContextProvider>
);

reportWebVitals();