import { MDBSpinner } from 'mdb-react-ui-kit'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`
const Loader = styled.span`
    width: 48px;

    height: 48px;
    border: 5px solid #145894;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: ${rotate} 1s linear infinite;
    margin: auto;
    padding: 20px;
`
const MiniLoader = () => {
  return (
    <>
        <MDBSpinner className='me-2' style={{ width: '3rem', height: '3rem' }}>
            <span className='visually-hidden'>Loading...</span>
        </MDBSpinner>
    </>
  )
}

export default MiniLoader