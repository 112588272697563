const config = {
    menuApi: 'https://fourdesign-adv-scent-menu.onrender.com'
}

// aws server instance api http://13.38.9.44:5000
// render api https://menu-node-latest.onrender.com
// https://menu-app-5ybf.onrender.com
// localhost http://localhost:5000

//https://fourdesign-adv-scent-menu.onrender.com

export default config;