import { useState, useEffect } from "react";
import config from "../../config";
import { useAuthContext } from "./useAuthContext";

const useCheckToken = () => {
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [isLoadingCheckToken, setIsLoading] = useState(true);
    const [errorCheckToken, setError] = useState(null);

    const { user } = useAuthContext()
    useEffect(() => {
        const fetchToken = async () => {
            try {
                const tokenToCheck = `Bearer ${user.token}`
                if (!tokenToCheck) {
                    throw Error("There is no user")
                }
    
                await fetch(`${config.menuApi}/check-token`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ tokenToCheck }),
                })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to validate token");
                    }
                    setIsTokenValid(true);
                })
                .catch((errorCheckToken) => {
                    
                    setError(errorCheckToken);
                })
                .finally(() => {
                    setIsLoading(false);
                });
            } catch (error) {
                setIsLoading(false)
                setError(error.message)
            }
        }
        fetchToken()
    }, []);

    return { isTokenValid, isLoadingCheckToken, errorCheckToken };
};

export default useCheckToken;