import { useEffect, useState } from "react";
import { useLogin } from "../hooks/useLogin";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Button, Form } from "react-bootstrap";
import '../css/bootstrap.min.css'
import MiniLoader from "../components/MiniLoader/MiniLoader";
import useCheckToken from "../hooks/useCheckToken";
import { useNavigate } from "react-router";
import { useAuthContext } from "../hooks/useAuthContext";
import { MDBBtn } from "mdb-react-ui-kit";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-admin-main);
    width: 100%;
    height: 100vh;
`
const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 3%;
    background-color: #fff;
    border-radius: 10px;
`
const LoginTitle = styled.h2``

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 30px;
    border-radius: 15px;
    max-width: 1500px;
    background-color: rgb(243, 244, 244);
    overflow-x: hidden;
    border: 1px lightgray solid;
    box-shadow: 0px 5px 20px 1px lightgray;
    display: flex;
    flex-direction: column;
    gap: 10px;

`
const ErrorDiv = styled.p`
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Login = () => {
    const { user } = useAuthContext()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { login, error, isLoading } = useLogin()
    const navigate = useNavigate()

    const handleLoginSubmit = async (e) => {
        e.preventDefault()
        await login(email, password)
    }

    const { isTokenValid, isLoadingCheckToken, errorCheckToken } = useCheckToken();

    if (isTokenValid || !errorCheckToken) {
        return navigate('/admin')
    }

    if (isLoadingCheckToken) {
        return (<div style={{ width: '100%', height: "100vh", display: 'flex', justifyContent: 'center', alignItems: "center" }}>
            <MiniLoader />
        </div>);
    }

    return (
        <>
            <div className="wrapper">
                <Helmet>
                    <meta property="og:title" content="Login" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.premium-club-asu.org" />

                    <title> Menu Admin Login </title>

                    <meta charSet="UTF-8" />
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta name="description" content="Premium Club is a Nonprofit Organization and one of the leading student activities in faculty of Commerce Ain shams University." />
                    <meta name="keywords" content="premium club,premium, premium club asu, club, asu, بريميم, premium student activity, student activity, كلية تجارة" />
                </Helmet>
            </div>
            <Container>
                <Wrapper >
                    <LoginTitle>
                        Menu Admin Login
                    </LoginTitle>
                    <Form onSubmit={handleLoginSubmit}>
                        <Form.Group>
                            <Form.Label htmlFor="">
                                Email
                                <Form.Control type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                            </Form.Label>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="">
                                Password
                                <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} value={password} />
                            </Form.Label>
                        </Form.Group>
                        <MDBBtn onClick={handleLoginSubmit} type="submit" disabled={isLoading} >
                            Login
                        </MDBBtn>
                        {error && <ErrorDiv>{error}</ErrorDiv>}
                    </Form>
                </Wrapper>
            </Container>
        </>
    )
}

export default Login;